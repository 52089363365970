import NavbarOther from "../components/NavbarOther/NavbarOther";
import productData from "../products.json";
import Product from "../components/Product/Product";
import "./StoneSheets.css";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";

const StoneSheets = () => {
  return (
    <>
      <Helmet>
        <title> Vkay Stone Veneer | Natural Stone Veneer </title>
        <meta
          name="description"
          content="Explore Vkay Stone Veneer's high-quality natural stone veneer products. Perfect for both interior and exterior applications, our stone veneers offer beauty and durability for your project."
        />
        <meta
          name="keywords"
          content="Stone Veneer Products, Natural Stone Veneer, High-Quality Stone Veneer, Exterior Stone Veneer, Interior Stone Veneer, Decorative Stone Veneer, Thin Stone Veneer, Manufactured Stone Veneer, Stacked Stone Veneer, Faux Stone Veneer, Stone Veneer Panels, Stone Veneer Siding, Architectural Stone Veneer, Custom Stone Veneer, Stone Cladding, Durable Stone Veneer, Affordable Stone Veneer, Stone Veneer for Walls, Stone Veneer for Fireplaces, Stone Veneer for Exteriors"
        />
        <meta
          property="og:title"
          content={`Vkay Stone Veneer`}
        />
        <meta
          property="og:description"
          content={`High-quality, durable, and beautiful stone veneer perfect for any application. Learn more about our premium stone products.`}
        />
        <meta property="og:type" content="product" />
        <meta
          property="og:url"
          content={`https://www.vkaystoneveneer.com/StoneSheet`}
        />
      </Helmet>

      <NavbarOther></NavbarOther>
      <div className="product-page">
        {productData.map((product, index) => (
          <Product key={index} product={product} />
        ))}
      </div>
      <Footer></Footer>
    </>
  );
};

export default StoneSheets;
