import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './Dashboard/Dashboard';
import ContactUs from './ContactUs/ContactUs';
import StoneSheets from './StoneSheets/StoneSheets';
import About from './About/About';
import StoneSheetsDetail from './StoneSheets/StoneSheetsDetail/StoneSheetsDetail';
import Manufacture from './Manufacture/Manufacture';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path=''>
        <Route path=""  element={<Dashboard />}>
          <Route index element={<Dashboard />}></Route>
        </Route>
        <Route path="about" element={<About />} ></Route>
        <Route path="contact" element={<ContactUs />} ></Route>
        <Route path="StoneSheet" >
          <Route index element={<StoneSheets />}></Route>
          <Route path=':url_name' element={<StoneSheetsDetail />}></Route>
        </Route>
        <Route path="Stone-Veneer-Manufacturer" element={<Manufacture />} ></Route>
        </Route>
        <Route path="/" element={<Navigate to="" />} />      </Routes>
    </BrowserRouter>
  );
}

export default App;
