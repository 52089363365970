import NavbarOther from "../components/NavbarOther/NavbarOther";
import "./About.css";
import vkayTag from "../assets/company/vkaytag.jpeg";
import aboutPageImage from "../assets/company/aboutusimage.png";
import Footer from "../components/Footer/Footer";
import React, { memo } from "react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Vkay Stone Veneer | Leading Stone Veneer Supplier</title>
        <meta
          name="description"
          content="Learn more about Vkay Stone Veneer, our mission, values, and commitment to providing high-quality stone veneer products. Discover why we are a trusted name in the industry."
        />
        <meta
          name="keywords"
          content="About Vkay Stone Veneer, Company History, Our Mission, Our Vision, Our Values, Stone Veneer Experts, Industry Leaders in Stone Veneer, Quality Commitment, Sustainability Practices, Customer Satisfaction, Innovative Stone Solutions, Our Team, Experienced Stone Veneer Manufacturers, Trusted Stone Veneer Suppliers, High-Quality Stone Veneer Products, Craftsmanship and Quality, Expert Stone Veneer Installation, Why Choose Vkay Stone Veneer, Leading Stone Veneer Company, Customer-Centric Approach"
        />
        <meta property="og:title" content="About Us | Vkay Stone Veneer" />
        <meta
          property="og:description"
          content="Discover the story of Vkay Stone Veneer, our mission, values, and our commitment to providing the highest quality stone veneer products and exceptional customer service."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.vkaystoneveneer.com/about"
        />
      </Helmet>

      <NavbarOther />
      <div className="about-page">
        <div className="about-container">
          <div className="about-title">ABOUT US</div>
          <div className="about-description">
            <p>
              <strong>Vkay Stone Veneer,</strong> we are passionate about
              crafting exquisite stone veneer products that elevate the
              aesthetics of any space. With years of experience in the industry,
              we have honed our expertise in sourcing premium quality natural
              stones and employing innovative manufacturing techniques to create
              stunning stone veneer sheets that inspire creativity and
              sophistication.
              <br />
              As a leading manufacturer, we pride ourselves on our commitment to
              excellence, integrity, and customer satisfaction. Our dedicated
              team of artisans and craftsmen work tirelessly to ensure that each
              stone veneer sheet meets the highest standards of quality and
              craftsmanship. From the initial selection of raw materials to the
              final inspection of finished products,
              <strong>
                {" "}
                we uphold rigorous quality control measures to deliver superior
                results that exceed expectations.
              </strong>
              <br />
              At <strong>Vkay Stone Veneer</strong>, we understand that every
              project is unique, which is why we offer a wide range of stone
              veneer options to suit various design preferences and
              architectural styles. Whether you're seeking a timeless classic or
              a contemporary statement piece, our diverse selection of colors,
              textures, and finishes provides endless possibilities for creative
              expression.
              <br />
              Beyond our commitment to quality and innovation, we are also
              dedicated to sustainability and environmental stewardship. We
              prioritize responsible sourcing practices, minimize waste
              generation, and continually seek ways to reduce our environmental
              footprint throughout the manufacturing process.
              <br />
              Above all,{" "}
              <strong>
                we value the relationships we build with our customers and
                partners
              </strong>
              . We strive to provide exceptional service, personalized
              attention, and expert guidance to ensure that your experience with
              Vkay Stone Veneer is nothing short of exceptional.
              <br />
              Thank you for considering Vkay Stone Veneer for your stone veneer
              needs. We look forward to the opportunity to collaborate with you
              and bring your vision to life.
            </p>
          </div>
        </div>
        <div className="about-page-image-container">
          <img
            className="about-page-image"
            src={aboutPageImage}
            alt="Different veneer colours"
          />
        </div>
      </div>
      <div className="marketing-container">
        <div className="about-page-image-container">
          <img
            className="vkaytag"
            height="300px"
            width="400px"
            src={vkayTag}
            alt="vkay-tag"
          />
        </div>
        <div className="marketing-container-text">
          <div className="marketing-title">
            Marketing / After Sales Services
          </div>
          <div className="about-description">
            <p>
              At <strong>Vkay Stone Veneer</strong>, our commitment to customer
              satisfaction doesn't end with a purchase. We provide comprehensive
              after-sales support to ensure your experience with our stone
              veneer products is seamless. From technical assistance and
              warranty coverage to maintenance tips and feedback channels, our
              dedicated team is here to address any questions or concerns you
              may have. We're committed to providing exceptional service every
              step of the way.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default memo(About);
