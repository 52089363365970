import CarouselComponent from "../components/Carousel/Carousel";
import { NavLink } from "react-router-dom";
import "./Home.css";
import aboutSectionPicture from "../assets/company/aboutsection.jpeg";
import manufacturingSectionPicture from "../assets/company/cutting-machine.jpeg";
import manufactureSectionPicture from "../assets/company/choose1.jpg";
import CompanyLogo from "../../src/assets/company/company.png";
import "../components/Navbar/Navbar";
import veneerheaderimg from "../../src/assets/company/veneerheaderimg.jpeg";
import { Helmet } from "react-helmet";

const AboutSection = () => (
  <div className="about-section">
    <div className="about-section-description">
      <h2> ABOUT US</h2>
      <p>
        Where elegance meets innovation in flexible stone veneer. With 25 years
        of experience, we're committed to transforming spaces with our
        high-quality, versatile products. Our flexible stone veneers blend
        natural beauty with modern convenience, crafted with premium materials
        and sustainable practices. We collaborate closely with architects,
        designers, and homeowners to deliver personalized solutions for
        residential, commercial, and hospitality projects worldwide. Discover
        the endless possibilities of flexible stone veneer with{" "}
        <b>Vkay Stone Veneer</b>.
      </p>
      <div className="learn-more-wrapper">
        <NavLink to="/about" className="learn-more">
          Learn More
        </NavLink>
      </div>
    </div>
    <div className="about-section-image">
      <img
        loading="lazy"
        className="home-images"
        src={aboutSectionPicture}
        alt="about-section"
      />
    </div>
  </div>
);

const ProductSection = () => (
  <div className="product-section">
    <div className="product-section-top-content">
      <h2>OUR STONE SHEETS</h2>
      <p>
        Welcome to Vkay Stone Veneer, where we offer a stunning array of stone
        veneer sheets meticulously crafted to elevate any space. Our diverse
        collection showcases the timeless beauty and natural allure of stone,
        combined with the versatility and ease of installation of modern
        veneers. Explore our range below:
      </p>
    </div>
    <div className="product-container">
      <CarouselComponent />
    </div>
    <div className="learn-more-wrapper-carousel">
      <NavLink to="/StoneSheet" className="learn-more">
        Learn More
      </NavLink>
    </div>
  </div>
);

const ManufacturingSection = () => (
  <div className="about-section">
    <div className="about-section-description">
      <h2>MANUFACTURING</h2>
      <p>
        Flexible stone veneer is made from a thin layer of stone stripped or
        peeled from a metamorphic stone marble chips or slab, rather than
        cutting from a solid stone or precast composite material. Thin veneers
        (from .5mm to 2mm thick) of slate, schist, or sandstone (metamorphic
        rocks) are pulled away from the original thicker stone slabs. This
        process only works with layered rock and marble but not with granite.
      </p>
      <div className="learn-more-wrapper">
        <NavLink to="/Stone-Veneer-Manufacturer" className="learn-more">
          Learn More
        </NavLink>
      </div>
    </div>
    <div className="about-section-image">
      <img
        loading="lazy"
        className="home-images"
        src={manufacturingSectionPicture}
        alt="about-section"
      />
    </div>
  </div>
);

const WhyChooseUsSection = () => (
  <div className="manufacture-section">
    <div className="manu-block">
    <div className="about-section-image">
      <img
        loading="lazy"
        className="home-whyus-images"
        src={manufactureSectionPicture}
        alt="about-section"
      />
    </div>
    <div className="about-section-description">
      <h2>WHY CHOOSE US?</h2>
      <p>
        At Vkay Stone Veneer, we stand out in the stone veneer industry for our
        unwavering commitment to quality, innovation, and customer satisfaction.
        Here's why discerning customers choose us:
      </p>
      <ul>
        <li>
          <b>Unmatched Quality:</b> We pride ourselves on delivering stone
          veneers of exceptional quality, meticulously crafted using premium
          materials and cutting-edge manufacturing techniques.
        </li>
        <li>
          <b>Customization Options:</b> We understand that every project is
          unique, which is why we offer customizable solutions tailored to our
          customers' specific needs and preferences.
        </li>
        <li>
          <b>Exceptional Service:</b> At Vkay Stone Veneer, customer
          satisfaction is our top priority. From the initial inquiry to the
          final delivery.
        </li>
        <li>
          <b>Proven Track Record:</b> With years of industry experience and a
          proven track record of success, we have earned the trust and loyalty
          of homeowners, architects, designers, and contractors alike. Our
          reputation for reliability, integrity, and excellence speaks volumes
          about the quality of our products and services.
        </li>
      </ul>
      
    </div>
    </div>
      <div className="learn-more-wrapper">
      <p>
        When you choose Vkay Stone Veneer, you're not just investing in stone
        veneers – you're investing in quality, craftsmanship, and peace of mind.
        Discover the difference that sets us apart and transform your space with
        the timeless beauty of natural stone veneers.
      </p>
        <NavLink to="/Stone-Veneer-Manufacturer" className="learn-more">
          Learn More
        </NavLink>
      </div>
  </div>
);

const Home = () => {
  return (
    <div className="root-body">
      <Helmet>
        <title>
          Vkay Stone Veneer | High-Quality Natural Stone Veneer Products
        </title>
        <meta
          name="description"
          content="Vkay Stone Veneer offers premium natural stone veneer for interior and exterior applications. Discover our wide range of stone cladding products and transform your space with elegance and durability."
        />
        <meta
          name="keywords"
          content="stone veneer sheets, natural stone veneer sheets, stone cladding, exterior stone veneer sheets, interior stone veneer sheets, stone veneer panels, thin stone veneer sheets, manufactured stone veneer sheets, decorative stone veneer sheets, stone veneer siding, stacked stone veneer, stone veneer installation, faux stone veneer, architectural stone veneer, custom stone veneer, stone veneer products, stone veneer walls, stone veneer suppliers, high-quality stone veneer sheets, durable stone veneer"
        />
        <meta
          property="og:title"
          content="Vkay Stone Veneer | High-Quality Natural Stone Veneer Products"
        />
        <meta
          property="og:description"
          content="Explore our collection of high-quality natural stone veneer products. Perfect for both interior and exterior applications, Vkay Stone Veneer's stone cladding offers unmatched beauty and durability."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.vkaystoneveneer.com" />
      </Helmet>
      <div className="navbar-container">
        <div className="navbar-menu-container">
          <div>
            <img
              loading="lazy"
              className="footer-logo"
              src={CompanyLogo}
              alt="Vkaystoneveneer logo"
            />
          </div>
          <div>
            <ul className="nav-items">
              <NavLink to="/" className="each-nav-item">
                HOME
              </NavLink>
              <NavLink to="/about" className="each-nav-item">
                ABOUT US
              </NavLink>
              <NavLink to="/StoneSheet" className="each-nav-item">
                STONE VENEER SHEETS
              </NavLink>
              <NavLink
                to="/Stone-Veneer-Manufacturer"
                className="each-nav-item"
              >
                MANUFACTURING
              </NavLink>
            </ul>
          </div>
          <div className="send-enquiry-box">
            <NavLink to="/contact" className="send-enquiry-header">
              CONTACT
            </NavLink>
          </div>
        </div>
        <div className="home">
          <div className="home-description-container">
            <div className="home-description-title">
             <h1> <b>FLEXIBLE STONE VENEER</b> </h1>is an innovative material and such a
              great alternative to heavy stone.
            </div>
            <div className="home-description">
              <h3>Flexible stone veneer is made from a thin layer of stone.</h3>
            </div>
          </div>
          <div className="home-image-container">
            <img
              loading="lazy"
              width="600px"
              height="400px"
              className="Veneer-Image"
              src={veneerheaderimg}
              alt="Veneer"
            />
          </div>
        </div>
      </div>

      <AboutSection />
      <ProductSection />
      <ManufacturingSection />
      <WhyChooseUsSection />
    </div>
  );
};

export default Home;
