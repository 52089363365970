import NavbarOther from "../components/NavbarOther/NavbarOther";
import manufacturing from "../assets/company/factory1.jpeg";
import ProductCarousel from "../components/ProductCarousel/ProductCarousel";
import "./Manufacture.css";
import Footer from "../components/Footer/Footer";
import { Helmet } from 'react-helmet';


const Manufacture = () => {
  let manufactureImages = [
    "rawmaterial1.png",
    "productionprocess1.jpeg",
    "qualitycontrol1.jpeg",
    "employeetrain1.jpeg",
    "customersatisfaction1.png",
  ];

  return (
    <>  
    <Helmet>
      <title>Stone Veneer Manufacturing Process | Vkay Stone Veneer</title>
      <meta name="description" content="Learn about Vkay Stone Veneer's advanced manufacturing process for high-quality natural stone veneer. Discover how we ensure durability and beauty in every product." />
      <meta name="keywords" content="stone veneer manufacturing, natural stone veneer, stone veneer process, Vkay Stone Veneer, durable stone veneer, high-quality stone veneer" />
      <meta property="og:title" content="Stone Veneer Manufacturing Process | Vkay Stone Veneer" />
      <meta property="og:description" content="Explore Vkay Stone Veneer's detailed manufacturing process. Learn how our expertise and technology produce the finest natural stone veneer products." />
    </Helmet>
      <NavbarOther></NavbarOther>
      <div className="manufacturing-page">
        <div className="manufacturing">
          <div className="manufacturing-image">
            <img
              className="lab-image"
              src={manufacturing}
              alt="manufacturing"
            />
          </div>
          <div className="manufacturing-text">
            <h1> MANUFACTURING</h1>
            <p>
              In the manufacturing process of stone veneer sheets, raw materials
              such as marble, slate, or quartzite are sourced from quarries and
              cut into thin slices of desired thickness. These slices undergo
              surface finishing techniques like polishing or honing to achieve
              the desired texture and appearance. Optionally, backing materials
              may be applied for reinforcement. After quality control
              inspections, the finished stone veneer sheets are packaged and
              distributed to wholesalers, retailers, or customers for use in
              construction and design projects, with a focus on implementing
              sustainable practices to minimize environmental impact throughout
              the production process. We are the best stone veneer manufacturer in Jaipur, India. 
            </p>
          </div>
        </div>
        <div className="manufacturing">
          <div className="manufacturing-text">
            <h1>Extensive R&D and Quality Control</h1>
            <p>
              At Vkay Stone Veneer rigorous procedures will be established to
              ensure the consistency and excellence of our stone veneer sheets.
              This will involve thorough inspection of incoming raw materials to
              meet specified standards, continual monitoring of production
              processes to minimize variations, and comprehensive testing of
              finished products for durability, adhesion, and appearance.
              Detailed documentation and traceability systems will be maintained
              to track materials and identify any deviations, while employee
              training programs will ensure staff are equipped to uphold quality
              standards. Customer feedback will be actively sought to address
              concerns and improve processes, all while ensuring compliance with
              relevant regulations and industry standards. Through these
              measures, we are committed to delivering stone veneer sheets of
              the highest quality, fostering customer trust and satisfaction.{" "}
            </p>

            <h3> Process we follow to deliver best quality</h3>
            <ul>
              <li>Raw Material Inspection</li>
              <li>Production Process Monitoring</li>
              <li>Quality Testing</li>
              <li>Documentation and Traceability</li>
              <li>Employee Training</li>
              <li>Customer Feedback and Satisfaction</li>
              <li>Compliance with Regulations</li>
            </ul>
          </div>
          <div className="manufacturing-image">
            <ProductCarousel imageList={manufactureImages}></ProductCarousel>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Manufacture;
